h2
    font-size: 1.7rem
    color: #fff

p
    color: #fff

h3
    font-size: 1.7rem
    color: #fff

.card-coins
    margin-top: 10px
    display: flex
    flex-direction: row
    align-items: center
    width: 100px
    justify-content: space-between

.card-stock
    display: flex
    width: 50px
    align-items: center
    justify-content: space-between
    background-color: #E50051
    border-radius: 10px
    padding: 5px

    & h4 
        color: white

.card-stock-disable 
    display: flex
    width: 50px
    align-items: center
    justify-content: space-between
    background-color: #7d7d7d
    border-radius: 10px
    padding: 5px

    & h4 
        color: white



.card-actions-hide
    display: flex
    justify-content: space-between

   


