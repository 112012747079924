.unidade-body-full
    background-image: url('../../imgs/back_home.svg')
    height: 100vh
    background-size: cover
    display: flex
    align-items: center
    justify-content: center

.unidade-body
    background-color: #3f1953
    border-radius: 12px
    padding: 15px
    text-align: center
    display: flex
    flex-direction: column
    justify-content: space-around

.unidade-title h2
    margin:10px
    
.unidade-buttons
    display: flex
    flex-direction: column
    justify-content: space-between
    margin: 5%

@media screen and (max-width: 378px) 

    .unidade-body 
        margin: 20px   

    
