.pn-body
    background-image: url('../../../imgs/back-login.svg')
    height: max-content 

.pn-menu-left
    background-color: #3f1953
    position: fixed
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 17%
    min-height: 92vh
    max-height: max-content
    background-size: cover

.pn-menu-left-buttons
    padding-top: 30px
    padding-bottom: 30px
    margin: 4%
    display: flex
    flex-direction: column
    justify-content: space-around
    height: 65vh

.pn-menu-left-data
    padding-top: 30px
    padding-bottom: 30px
    padding: 30px 10px 30px 10px 
    display: flex
    flex-direction: column
    background-color: #2a0f38

    & h5
        color: white
        font-size: 1.2rem

    & h6 
        color: white
        font-size: 0.8rem    

.pn-menu-and-body
    display: flex
    height: max-content

.pn-conteudo
    display: flex
    flex-direction: row

@media screen and (max-width: 900px)
    .pn-menu-left
        display: none
