.delproduct-body
    height: 20vh
    display: flex
    flex-direction: column
    justify-content: space-around
    margin: 20px

.delproduct-title 
    position: absolute
    background-color: #c41653
    height: 7vh
    border-radius: 12px
    display: flex
    align-items: center
    justify-content: center
    width: 45%
    top: -16%

    & h2
        color: white
        font-size: 1.1rem
        text-align: left
        margin: 10px

.delproduct-text 

    h3 
        font-size: 1.5rem
    h4
        font-size: 1.2rem
        color: #c8c8c8
        margin-top: 10px

.delproduct-buttons
    display: flex
    justify-content: flex-end

@media screen and (max-width: 900px) 
    .delproduct-title 
        width: 60%

@media screen and (max-width: 378px) 
    .delproduct-title 
        width: 70%
        top:-19%