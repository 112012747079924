.get-several-body
    margin-left: 17%
    width: 100%
    display: flex
    height: 92vh
    justify-content: center
    align-items: center

.get-several-card
    display: flex
    flex-direction: column
    align-items: center
    background-color: #3f1953
    border-radius: 18px
    padding: 2%
    width: 60%
    height: 80%

.button-groups-several 
    display: flex
    justify-content: flex-start
    width: 93%
    flex-wrap: wrap

.button-several-alunos
    display: flex
    align-items: center
    margin: 10px
    color: white
    background-color: #9262aa3c
    border-radius: 12px
    border: none
    padding: 10px
    cursor: pointer

.get-serch-sev
    margin-top: 3%
    display: flex
    align-items: center
    justify-content: center
    width: 90%

.button-several-alunos:hover
    background-color: #54216f

.get-info-aluno-sev
    display: flex
    flex-direction: column
    justify-content: space-around
    align-items: center
    background-color: white
    border-radius: 5px
    width: 90%
    height: 40%
    margin: 3% 0 3% 0

    & h2
        color: #3f1953

.get-user-saldo-sev
    width: 50%
    display: flex
    flex-direction: column
    align-items: center

    & h5
        font-size: 1.4rem

@media screen and (max-width: 900px)
    .get-several-body
        margin: 0

    .get-several-card
        border-radius: 0
        width: 100%
        height: 100%
        padding: 0 5% 0 5%

    .get-info-aluno-sev
        width: 100%
        border-radius: 5px
        height: 60vh

    .get-user-saldo-sev
        width: 90%

    .get-serch-sev
        width: 100%
        margin-top: 20%
    
