.pproduct-body-full-edit
    width: 100%
    display: flex
    justify-content: center
    align-items: center

.pproduct-body-edit
    width: 100%
    background-color: #2d0d3d
    border-radius: 12px
    display: flex
    flex-direction: column
    justify-content: space-around
    align-items: center
    height: 75vh

.pproduct-title-edit
    position: absolute
    background-color: #c41653
    height: 7vh
    border-radius: 12px
    display: flex
    align-items: center
    justify-content: center
    width: 20%
    top: -5%
    left: 1%

    & h4
        color: white
        font-size: 1.2rem
        text-align: left
        margin: 10px

.pproduct-body-form
    display: flex
    flex-direction: row
    justify-content: space-around
    width: 100%

.pproduct-body-form-inputs
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 55%

.pproduct-inputs
    display: flex
    flex-direction: column
    justify-content: space-around
    width: 100%
    height: 40vh

.pproduct-card
    width: 35%

.pproduct-title-edit-mobile
    display: none

@media screen and (max-width: 900px)
    .pproduct-body-full-edit
        padding-top: 25%

    .pproduct-title-edit
        display: none

    .pproduct-title-edit-mobile
        display: block
        background-color: #c41653
        height: 7vh
        border-radius: 12px
        display: flex
        align-items: center
        justify-content: center
        width: 85%
        color: white
        margin: 10px
        margin-bottom: 35px

    .pproduct-card
        height: 42vh

@media screen and (max-width: 380px)
    .pproduct-title-edit-mobile
        width: 87%
        margin-top: 30px
        margin-bottom: 50px

    .pproduct-card
        height: 52vh
