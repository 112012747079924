.home-body-full
    display: flex
    flex-direction: column
    align-items: center
    background-image: url('../../imgs/back_home.svg')
    min-height: 90vh
    height: max-content

.home-body
    width: 100%
    max-width: 980px
    background-color: #311042c7
    margin-top: 30px
    margin-bottom: 30px
    border-radius: 14px
    padding: 1%

.home-banner
    margin-top: 20px
    background-image: url('../../imgs/bannerHome.png')
    height: 30vh
    background-size: cover
    border-radius: 10px
    margin-bottom: 30px

.home-body h1 
    background-color: #E50051
    height: 5vh
    margin: 20px 0 20px 0
    color: white
    font-size: 1.2rem
    display: flex
    justify-content: center
    align-items: center
    border-radius: 12px

.home-cards
    display: grid
    grid-template-columns: 32% 32% 32%
    grid-column-gap: 20px
    grid-row-gap: 1em
    justify-content: center
    margin-bottom: 30px
    min-height: 60vh

@media screen and (max-width: 900px)

    .home-body
        margin: 0px
        border-radius: 0px
        padding: 0px
        background-color: #31104200

    .home-body h1 
        height: 8vh
        padding: 20px
        margin: 0px 20px 70px 20px

    .home-banner
        margin-top: 0px
        border-radius: 0px
        height: 50vh
        overflow: hidden
        margin: 20px 15px 70px 15px
        border-radius: 12px


    .home-cards
        display: flex
        background-color: #311042c7
        border-radius: 12px
        padding: 20px
        flex-direction: column
        align-items: center
        margin: 10px 20px 30px 20px
