.pproduct-body-full
    width: 80%
    margin-left: 17%
    display: flex
    justify-content: center
    align-items: center
    height: 92vh

.pproduct-body
    width: 70%
    background-color: #2d0d3d
    border-radius: 12px
    display: flex
    flex-direction: column
    justify-content: space-around
    align-items: center
    height: 75vh

.pproduct-title
    position: absolute
    background-color: #c41653
    height: 7vh
    border-radius: 12px
    display: flex
    align-items: center
    justify-content: center
    width: 15%
    top: 13%
    left: 30%

    & h4
        color: white
        font-size: 1.2rem
        text-align: left
        margin: 10px

.pproduct-title-mob
    display: none

.pproduct-body-form
    display: flex
    flex-direction: row
    justify-content: space-around
    width: 100%

.pproduct-body-form-inputs
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 55%

.pproduct-inputs
    display: flex
    flex-direction: column
    justify-content: space-around
    width: 100%
    height: 40vh

.pproduct-card
    width: 35%

@media screen and (max-width:900px)
    .pproduct-body-full
        width: 100%
        margin-left: 0

    .pproduct-body
        width: 100%
        height: max-content
        border-radius: 0px

    .pproduct-title
        display: none

    .pproduct-title-mob

    .pproduct-body-form
        display: flex
        align-items: center
        flex-direction: column-reverse !important

    .pproduct-form
        display: none

    .pproduct-body-form-inputs
        height: 60vh
        width: 80% !important
        padding-bottom: 10vh

    .pproduct-card
        padding-top: 50vh
        width: 80% !important
        padding-bottom: 15vh

    .pproduct-title-mob
        display: block
        background-color: #c41653
        height: 7vh
        border-radius: 12px
        display: flex
        align-items: center
        justify-content: center
        width: 85%
        color: white
        margin: 10px
        margin-bottom: 35px

@media screen and (max-width:380px)

    .pproduct-body-form-inputs
        height: 80vh

    .pproduct-inputs
        height: 50vh !important

    .pproduct-card
        margin-top: 60%
