.vp-full-body
    width: 80%
    flex-direction: column
    align-items: center
    display: flex
    justify-content: center
    margin-left: 18%
    min-height: 92vh
    

@media screen and (max-width: 900px) 
    .vp-full-body    
        width: 100%
        margin-left: 0
