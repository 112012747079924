.hide-title
    position: absolute
    background-color: #c41653
    height: 7vh
    border-radius: 12px
    display: flex
    align-items: center
    justify-content: center
    width: 40%
    top: -16%

@media screen and (max-width: 900px) 
    .hide-title
        width: 80%
        top:-12%