.pedidos-body
    width: 100%
    margin-left: 17%
    display: flex
    justify-content: center
    align-items: center
    height: 92vh

.pedidos-body-limit
    width: 80%

.pedidos-body-table
    background-color: rgba(49, 16, 66, 0.7803921569)
    padding: 40px
    border-radius: 12px
    position: relative

.pedidos-body-table h1
    position: absolute
    z-index: 3
    top: -50px
    left: 1%
    background-color: #c41653
    width: 30%
    padding: 20px
    border-radius: 12px
    color: white
    font-size: 1.2rem
    text-align: left
    margin: 10px
    
.pedidos-body-table h2 
    position: absolute
    z-index: 3
    top: -50px
    left: 1%
    background-color: #c41653
    width: 24%
    padding: 20px
    border-radius: 12px
    color: white
    font-size: 1.2rem
    text-align: center
    margin: 10px
    font-size: 1rem

.title-dados-aluno 
    position: absolute
    z-index: 1
    top: -30px
    left: 1%
    background-color: #c41653
    width: 40%
    padding: 10px
    border-radius: 12px
    color: white
    font-size: 1.2rem
    text-align: center
    margin: 10px
    font-size: 1rem

.body-dados-aluno h1
    color: white
    
@media screen and (max-width: 900px) 

    .pedidos-body
        width: 100%
        margin: 0px
        height: 92vh

    .pedidos-body-limit
        width: 100%

    .pedidos-body-table
        padding: 15px
        
    .pedidos-body-table h1
        top: -43px
        left: 20%
        width: 48%

    .pedidos-body-table h2 
        display: none

    

@media screen and (max-width: 380px) 

    .pedidos-body-table h1
        top: -30px
        padding: 10px
        width: 54%




