.put-unidade-body-full
    display: flex
    flex-direction: column

.put-unidade-title
    position: absolute
    background-color: #c41653
    border-radius: 12px
    display: flex
    align-items: center
    justify-content: center
    height: 7vh
    width: 60%
    top: -9%
    left: 5%

.put-unidade-inputs
    display: flex
    flex-direction: column
    height: 20vh
    justify-content: space-around

@media screen and (max-width: 900px)
    .put-unidade-title
        width: 50%
        font-size: 1rem


@media screen and (max-width: 380px)
   .put-unidade-inputs
        height: 30vh

