.footer-full-body
    display: flex
    justify-content: center
    background-color: #311042
    background-image: url('../../imgs/listras.png')
    background-repeat: no-repeat

.footer-body
    max-width: 980px
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    justify-content: space-around
    height: 30vh
    padding: 30px 0 30px 0

    & p
        color: white

#footer-logo-mob
    display: none

@media screen and (max-width: 900px)

    .footer-body
        height: 60vh

        & p
            text-align: center

    #footer-logo-mob
        display: block

    #footer-logo
        display: none
