.login-body
    background-image: url('../../imgs/back-login.svg')
    background-size: cover
    height: 92vh
    display: flex
    align-items: center
    justify-content: center

.login-card
    display: flex
    flex-direction: column
    justify-content: space-around
    background-color: #E50051
    height: 50vh
    width: 25%
    padding: 50px
    border-radius: 18px
    position: absolute
    left: 15%
    z-index: 1
    -webkit-box-shadow: -15px 11px 18px 0px rgba(0,0,0,0.75)
    -moz-box-shadow: -15px 11px 18px 0px rgba(0,0,0,0.75)
    box-shadow: -15px 11px 18px 0px rgba(0,0,0,0.75)
    

    & img
        display: none

    & h3,p
        color: white
    & h3
        font-weight: 700
        font-size: 1.5rem
        text-align: center

.login-inputs
    display: flex
    flex-direction: column
    height: 20vh
    justify-content: space-around

.login-acces
    display: flex
    justify-content: space-between
    align-items: center

    & p
        font-size: 0.9rem
    & h5
        color: white
        font-weight: 400

.login-card-message
    display: flex
    flex-direction: column
    justify-content: space-around
    text-align: center
    background-image: url('../../imgs/back-msg.svg')
    border-radius: 18px
    width: 35%
    height: 70vh
    position: absolute
    padding: 3%
    left: 43%
    -webkit-box-shadow: -15px 11px 18px 0px rgba(0,0,0,0.75)
    -moz-box-shadow: -15px 11px 18px 0px rgba(0,0,0,0.75)
    box-shadow: -15px 11px 18px 0px rgba(0,0,0,0.75)

    & h2
        color: white
        padding-bottom: 100px


@media screen and (max-width: 1600px)
    .login-card-message
        padding: 30px 70px 3px 70px

@media screen and ( max-width: 900px )

    .login-body
        align-items: flex-start
        box-shadow: 0px
        background-image: none
        background: linear-gradient(180deg, rgba(63,25,83,1) 75%, rgba(49,16,66,1) 100%)

    .login-card-message
        display: none
        -webkit-box-shadow: 0px
        -moz-box-shadow: 0px
        box-shadow: -0px

    .login-card
        width: 85%
        border-radius: 12px
        position: relative
        top: 5%
        left: 0
        padding: 20px
        height: 70vh
        background-color: #552370
        align-items: center
        justify-content: space-evenly
        box-shadow: none

        & h3
            text-align: center
            font-size: 1.3rem

        & img
            display: block
            width: 90%

    .login-inputs
        width: 100%
        min-height: 150px
