.logo-header
    display: flex

.invisivel
    display: none

@media screen and (max-width: 900px)
    .logo-header
        justify-content: center

    .logo-header-mob
        margin: 40px

    .invisivel
        display: block
