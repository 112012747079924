.logo-header 
    display: flex
    
@media screen and (max-width: 900px) 
    .logo-header-inst 
        justify-content: center
        display: none

    .logo-header-mob
        margin: 40px
    