.get-body
    width: 80%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin-left: 18%
    height: 92vh

.get-card
    padding: 40px 20px 20px 20px
    width: 60%
    height: 70vh
    background-color: #3f1953
    border-radius: 14px
    text-align: center
    display: flex
    flex-direction: column
    align-items: center

    

    & hr
        width: 90%
        margin-bottom: 15px
    & p
        color: white

.get-title
    position: absolute
    background-color: #c41653
    height: 7vh
    border-radius: 12px
    display: flex
    align-items: center
    justify-content: center
    width: 30%
    top: 12%
    left: 34%

    & h4
        color: white
        font-size: 1.2rem
        text-align: left
        margin: 10px

.get-serch
    display: flex
    align-items: center
    justify-content: center
    width: 90%

.get-info-aluno
    margin-top: 4%
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    background-color: white
    border-radius: 5px
    width: 90%

    & h2
        color: #3f1953

.get-section-aluno
    display: flex
    align-items: center
    width: 100%
    justify-content: center

.get-user-picture
    border-radius: 80px
    border-style: solid
    border-color: red
    margin: 20px

.get-user-saldo
    width: 50%
    display: flex
    align-items: center

    & h5
        font-size: 1.4rem

.get-user-manivela
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    padding-top: 2vh
    height: 55vh

.get-user-space-button
    width: 50%
    margin-bottom: 2%

@media screen and (max-width: 900px)

    .get-body
        width: 100%
        margin-left: 0

    .get-card
        padding: 5%
        width: 90%
        height: 91vh
        border-radius: 0
        height: 88vh

        & hr
            width: 100%

    .get-title
        top: 10%
        left: 5%
        width: 90%
        justify-content: center

        & h4 
            font-size: 1rem

    .get-serch
        width: 100%
        margin-top: 60px 

    .get-info-aluno
        width: 100%
        border-radius: 5px
        height: 68vh
        

    .get-user-saldo
        width: 90%

    .get-user-space-button
        width: 90%

