.del-uni-body
    height: 15vh
    display: flex
    flex-direction: column
    justify-content: space-around

.del-uni-title
    position: absolute
    background-color: #c41653
    color: white
    border-radius: 12px
    display: flex
    align-items: center
    justify-content: center
    height: 6vh
    width: 40%
    top: -25px
    left: 3%

.del-uni-button
    display: flex
    justify-content: flex-end

