.punidade-body-full
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    height: 92vh
    padding-left: 20%
    width: 100%

.punidade-body
    display: flex
    flex-direction: column
    justify-content: space-around
    background-color: #3f1953
    height: 60vh
    width: 40%
    border-radius: 15px
    padding: 20px
    text-align: center

.punidade-header
    position: absolute
    background-color: #c41653
    display: flex
    justify-content: center
    align-items: center
    width: 18%
    top: 18%
    left: 44%
    height: 7vh
    border-radius: 12px

    & h2 
        color: #fff
        font-size: 1.4rem
        text-align: left
        margin: 10px

.punidade-inputs
    display: flex
    flex-direction: column
    justify-content: space-between
    height: 35vh

@media screen and (max-width: 900px) 

    .punidade-body-full
        background-color: #4d1b68
        height: 91vh
        padding-left: 0%

    .punidade-body
        width: 80%

    .punidade-header
        width: 50%
        left: 7%

        & h2 
            font-size: 1rem

    .punidade-inputs
        height: 40vh
