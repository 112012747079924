.change-pu-body
    display: flex
    flex-direction: column
    justify-content: space-around
    height: 20vh

.change-pu-title
    position: absolute
    top: -40px
    left: 1%
    background-color: #c41653
    width: 30%
    padding: 15px
    border-radius: 12px
    color: white
    font-size: 1.2rem
    text-align: center
    margin: 10px

.change-pu-buttons
    display: flex
    justify-content: flex-end

@media screen and (max-width: 900px ) 
    
    .change-pu-title
        width: 40%


@media screen and (max-width: 380px) 

    .change-pu-body
        height: 30vh
